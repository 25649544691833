<template>
    <info-toast :infoMessage="infoMessage" @resetInfoMessage="infoMessage = null">
        <template v-slot:info-message>
            <span class="pl-2 text-md">{{ infoMessage }}</span>
        </template>
    </info-toast>
    <error-toast :errorMessage="errorMessage" @resetErroMessage="errorMessage = null">
        <template v-slot:error-message>
            <span class="pl-2 text-md">{{ errorMessage }}</span>
        </template>
    </error-toast>
    <success-toast :successMessage="successMessage" @resetSucessMessage="successMessage = null">
        <template v-slot:success-message>
            <span class="pl-2 text-md">{{ successMessage }}</span>
        </template>
    </success-toast>
    <nav v-if="quote" class="flex justify-between items-center">
        <BackArrow
            :pageTitles="{
                heading: 'Request for Quotation ',
                content: 'BACK TO Request for quote',
            }"
        />
        <router-link
            :to="{ name: 'vendorBid', params: { quoteId: quote.id } }"
            class="text-white bg-primaryColor rounded py-2 px-4 mr-32 capitalize"
            >View Vendors Bid</router-link
        >
    </nav>
    <main v-if="quote" class="bg-white mx-32 my-8 pt-10 pb-20 rounded-lg shadow text-sm">
        <h1 class="text-lg mx-10 font-bold capitalize">{{ quote.project_name }}</h1>
        <hr class="my-6" />
        <div class="flex text-sm mb-16 mx-10">
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">Deadline</h1>
                <div>{{ moment(quote.project_deadline).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">Date Created</h1>
                <div>{{ moment(quote.created_at).format('D MMM, YYYY') }}</div>
            </div>
            <div class="w-1/4">
                <h1 class="text-primaryColor capitalize">to</h1>
                <div>Vendors</div>
            </div>
        </div>
        <h1 class="text-lg mx-10 font-bold capitalize">Expenditure Request</h1>
        <hr class="my-6" />
        <div class="px-10">
            <table class="w-full table-fixed border border-borderColor capitalize mt-4">
                <thead class="font-semibold text-left capitalize">
                    <tr class="rounded-md">
                        <th class="w-1/2 border border-borderColor p-3">expenditure</th>
                        <th class="w-1/2 border border-borderColor p-3">Decription</th>
                        <th class="w-1/4 border border-borderColor p-3">Project Deadline</th>
                        <th class="w-1/4 border border-borderColor p-3">Quantity</th>
                        <th class="w-1/4 border border-borderColor p-3">Unit Price</th>
                        <th class="w-1/4 border border-borderColor p-3">Taxes</th>
                        <th class="w-1/4 border border-borderColor p-3">net price</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="request in quote.expenditure_request" :key="request.id">
                        <td class="border border-borderColor p-3 capitalize">
                            {{ request.product_name }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ request.product_description }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ moment(quote.project_deadline).format('D MMM, YYYY') }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ request.quantity }}
                        </td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{ $filters.currencyUSD(request.unit_cost) }}.00
                        </td>
                        <td class="border border-borderColor p-3 capitalize">0.00</td>
                        <td class="border border-borderColor p-3 capitalize">
                            {{
                                $filters.currencyUSD(
                                    Number(request.quantity) *
                                        Number(request.unit_cost.split('.')[0])
                                )
                            }}.00
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <section v-if="total" class="flex flex-row-reverse my-10 mx-10">
            <div>
                <div class="text-xl">
                    <span class="uppercase">total:</span>
                    <span class="font-bold ml-2 bg-interactionBg rounded-md p-3 shadow-sm"
                        >N {{ $filters.currencyUSD(total) }}.00</span
                    >
                </div>
            </div>
        </section>
        <section v-if="quote && quote.status.toLowerCase() === 'initiated'">
            <h1 class="text-lg leading-9 font-bold mt-20 mx-10">Vendors</h1>
            <hr class="my-6" />
            <div class="w-full flex items-end justify-between px-10">
                <div class="w-96 relative">
                    <input
                        v-if="vendors"
                        v-model="vendorSearchTerm"
                        type="text"
                        placeholder="Search for vendors..."
                        class="block w-full border border-borderColor rounded focus:outline-none p-2"
                    />
                    <div class="flex justify-between items-center my-10 capitalize">
                        <span>all vendors</span>
                        <span
                            @click="addAllVendors"
                            class="w-16 text-white bg-primaryColor rounded py-1 text-xs text-center cursor-pointer"
                            >add</span
                        >
                    </div>
                    <div
                        v-for="vendor in vendorsOnRFQ"
                        :key="vendor.id"
                        class="flex justify-between items-center mt-4 capitalize"
                    >
                        <span>{{ vendor.name }}</span>
                        <span
                            @click="removeVendor(vendor)"
                            class="w-16 text-white bg-pillRejectedStatus rounded py-1 text-xs text-center cursor-pointer"
                            >remove</span
                        >
                    </div>
                    <ul
                        v-if="showFilteredResult"
                        class="absolute top-10 bg-white border border-borderColor rounded w-full max-h-48 overflow-y-scroll"
                    >
                        <li
                            @click="selectVendor(vendor)"
                            v-for="vendor in filtereResult"
                            :key="vendor.vendor_details.id"
                            class="px-2 py-3 hover:bg-lightGreyBg cursor-pointer"
                        >
                            {{ vendor.vendor_details.name }}
                        </li>
                    </ul>
                </div>
                <SubmitButton @submitRequest="updateVendorList" buttonText="Update Vendors List" />
            </div>
        </section>
    </main>
    <LoadingSpinner v-else />
</template>

<script>
import Request from '@/services/requestHelper'
import BackArrow from '@/components/ui/BackArrow.vue'
import SubmitButton from '@/components/ui/SubmitButton.vue'
import moment from 'moment'
import errorFunc from '@/util/error'

export default {
    name: 'quoteView',
    props: {
        quoteId: String,
    },
    components: { BackArrow, SubmitButton },
    async mounted() {
        try {
            this.getAllVendors()
            const { status, data } = await Request.getRequest(`request/quote/${this.quoteId}`)

            if (status >= 200 && status < 300) {
                this.quote = data.data
                this.vendorsOnRFQ = this.quote.vendors
                this.computeTotalValue(this.quote)
            }
        } catch (error) {
            errorFunc(error.response, this.$router)
            this.errorMessage = error.response.data.message
        }
    },
    watch: {
        vendorSearchTerm() {
            if (this.vendorSearchTerm) {
                const matchedVendors = this.vendors.filter(data =>
                    data.vendor_details.name
                        .toLowerCase()
                        .includes(this.vendorSearchTerm.toLowerCase())
                )
                this.filtereResult = matchedVendors
                this.showFilteredResult = true
            }
        },
    },
    data() {
        return {
            total: 0,
            quote: null,
            moment,
            vendors: null,
            infoMessage: null,
            errorMessage: null,
            successMessage: null,
            vendorsOnRFQ: [],
            vendorSearchTerm: null,
            filtereResult: null,
            showFilteredResult: false,
        }
    },
    methods: {
        async getAllVendors() {
            try {
                const { status, data } = await Request.getRequest('vendor')

                if (status >= 200 && status < 300) {
                    this.vendors = data.data.data
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
        selectVendor(vendor) {
            const vendorAvalable = this.checkAvailability(
                this.vendorsOnRFQ,
                vendor.vendor_details.id
            )

            if (!vendorAvalable) {
                this.vendorsOnRFQ.push(vendor.vendor_details)
                this.showFilteredResult = false
                this.vendorSearchTerm = null
            } else {
                this.infoMessage = 'This vendor is already on the  list.'
                this.showFilteredResult = false
                this.vendorSearchTerm = null
            }
        },
        addAllVendors() {
            this.vendors.forEach(vendor => {
                const vendorAvalable = this.checkAvailability(
                    this.vendorsOnRFQ,
                    vendor.vendor_details.id
                )

                if (!vendorAvalable) {
                    this.vendorsOnRFQ.push(vendor.vendor_details)
                }
            })

            this.vendorsOnRFQ = Array.from(new Set(this.vendorsOnRFQ))
        },
        removeVendor(vendor) {
            const vendorIndex = this.vendorsOnRFQ.indexOf(vendor)
            this.vendorsOnRFQ.splice(vendorIndex, 1)
        },
        checkAvailability(arr, val) {
            return arr.some(arrVal => val === arrVal.id)
        },
        computeTotalValue(quote) {
            quote.expenditure_request.forEach(tick => {
                this.total += Number(tick.quantity) * Number(tick.unit_cost.split('.')[0])
            })
        },
        async updateVendorList() {
            try {
                const vendorIds = this.vendorsOnRFQ.map(vendor => vendor.id)

                const formdata = new FormData()

                formdata.append('project_name', this.quote.project_name)
                formdata.append('project_deadline', this.quote.project_deadline)
                formdata.append('rfq_id', this.quote.id)

                for (let i = 0; i < vendorIds.length; i += 1) {
                    formdata.append('vendor_id[]', vendorIds[i])
                }

                const { status, data } = await Request.postRequest('request/quote/update', formdata)

                if (status >= 200 && status < 300) {
                    this.successMessage = data.message
                }
            } catch (error) {
                errorFunc(error.response, this.$router)
                this.errorMessage = error.response.data.message
            }
        },
    },
}
</script>
